import styles from "./index.module.scss"

export const Tag = ({ title, icon, isActive = false, onClick = () => { } }) => {
    return (
        <p className={`${styles.Tag} ${isActive ? styles.active : ''}`} onClick={onClick}>
            {icon ? <span>{icon}</span> : ''}{title}
        </p>
    )
}

export default function Tabs({ tabs = [], current = '', onChange = () => { }, align = 'left' }) {
    return (
        <div className={styles.TabsContainer}>
            <div className={`${styles.tabs} ${styles[align]}`}>
                {tabs.map((item, index) => {
                    return <div
                        className={`${styles.tab} ${(current === item.value) ? styles.active : ""}`}
                        onClick={() => onChange(item.value)}
                        key={index}
                    >
                        {item?.icon ? <div className={styles.iconContainer}>
                            <span className={styles.icon}>{item.icon}</span>
                            <span className={styles.active_icon}>{item.active_icon}</span>
                        </div> : ""}
                        <p className={styles.label}>{item.label}</p>
                    </div>
                })}
            </div>
            <hr className={styles.border} />
        </div>
    )
}
