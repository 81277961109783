import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./map.module.scss";

import _ from "lodash";
import {
    GoogleMap,
    Marker,
    StandaloneSearchBox
} from "@react-google-maps/api";
import { Toast } from "../../hooks/useToast"


const Map = ({ location, setLatLng = () => { }, address = '', setAddress = () => { }, option = {}, zoom = 9, className = '', seachBar = false, isEdit = false }) => {

    const componentWillUnmount = useRef(false)
    const mapRef = useRef();
    const [searchBox, setSearchBox] = useState(null);
    const [center, setCenter] = useState({ lat: 32.7767, lng: -97.030821 });
    const options = useMemo(() => ({
        mapId: "17c975ad78fa8aef",
        disableDefaultUI: true,
        clickableIcons: false,
        ...option
    }))

    const [position, setPosition] = useState(location)
    const [add, setAdd] = useState(address)

    useEffect(() => {
        // if (!location) {
        //     getCurrentLocation();
        // }

        return () => {
            componentWillUnmount.current = true
        }
    }, [])

    useEffect(() => {
        ; (async () => {
            if (!mapRef.current) return;
            if (!_.isEmpty(position)) {
                await mapRef.current.panTo(position);
                mapRef.current.setZoom(14);
            }
        })()

        return () => {
            if (componentWillUnmount.current) {
                if (!_.isEmpty(position)) {
                    setLatLng(_.isEmpty(position) ? null : {
                        lat: position.lat,
                        lng: position.lng,
                    });
                    setAddress(add)
                }
            }
        }
    }, [position])

    const getCurrentLocation = useCallback(() => {
        navigator.geolocation.getCurrentPosition(
            async function (position) {
                const { latitude, longitude } = position.coords;
                // setPosition({
                //     lat: latitude,
                //     lng: longitude
                // });
                await mapRef.current.panTo({ lat: latitude, lng: longitude });

            },
            function (error) {
                Toast("Site Not Able To access Your location !", "info", false);
            }
        );
    }, [])


    const onLoad = useCallback((map) => {
        mapRef.current = map;
        if (location) {
            mapRef.current.setZoom(14);
        }
    }, []);

    const onClick = ({ latLng }) => {
        // const lat = latLng.lat();
        // const lng = latLng.lng();
        // setPosition({ lat, lng });
    }

    const handleLoad = ref => {
        setSearchBox(ref);
    };

    const handlePlaceChange = async () => {
        const [place] = searchBox.getPlaces()
        const { lat, lng } = place.geometry.location;
        setAdd(place.formatted_address)
        setPosition({
            lat: lat(),
            lng: lng(),
        })

    }

    const handleAddressChange = (e) => {
        setAdd(e.target.value)
        if (!_.isEmpty(position)) {
            setCenter({ lat: position.lat, lng: position.lng })
            setPosition(null);
        }
    }

    return (

        <GoogleMap
            zoom={zoom}
            center={_.isEmpty(position) ? center : position}
            mapContainerClassName={`${styles.googleMap} ${className}`}
            options={options}
            onLoad={onLoad}
            onClick={onClick}
        >
            {!_.isEmpty(position) && <Marker position={position} />}
            {!seachBar ? "" : <StandaloneSearchBox
                onPlacesChanged={handlePlaceChange}
                onLoad={handleLoad}
            >
                <input
                    type="text"
                    placeholder="Search your address here"
                    value={add}
                    onChange={handleAddressChange}
                    style={{
                        boxSizing: 'border-box',
                        border: `1px solid transparent`,
                        width: `70%`,
                        maxWidth: `600px`,
                        height: `45px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        margin: 'auto',
                        textOverflow: `ellipses`,
                        position: 'absolute',
                        top: '60px',
                        left: '50%',
                        right: "50%",
                        transform: "translate(-50%,-50%)"
                    }}
                />
            </StandaloneSearchBox>
            }
        </GoogleMap >

    )
}

export default React.memo(Map)