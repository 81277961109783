import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import styles from "./map.module.scss";

import { useJsApiLoader } from "@react-google-maps/api";

import Map from "./Map.js"
import _ from "lodash";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function MapContainer({ location, setLatLng, address = '', setAddress = () => { }, setClose, isEdit = false, seachBar = true }) {
    const [libraries] = useState(['places']);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
        libraries: libraries
    })

    return (
        ReactDom.createPortal(<div className={styles.mapContainer}>
            <div className={styles.background} onClick={() => setClose(false)}>
            </div>
            <div className={styles.map}>
                {!isLoaded ?
                    <Spin indicator={antIcon} /> :
                    <Map
                        location={location}
                        setLatLng={setLatLng}
                        address={address}
                        setAddress={setAddress}
                        seachBar={seachBar}
                        isEdit={isEdit}
                    />
                }
            </div>
        </div>, document.getElementById("modal"))
    )
}

export default React.memo(MapContainer)